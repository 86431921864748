<template>
  <div>
    <GlobalForm class="global-form" :needBtnLoading="true" :init-data="initData" :form-item-list="formItemList" :inline="true" :labelWidth="40"
      round @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label-width="60px" label="添加人" slot="operationSelect" style="margin:0">
        <el-select v-model="userAccount" placeholder="请选择" clearable filterable @change="selecthandleChange">
          <el-option v-for="item in shanpsSelectList" :key="item.userAccount" :label="item.userName"
            :value="item.userAccount"></el-option>
        </el-select>
      </el-form-item>
      <template>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addOneMenu">添加海报
        </el-button>
      </template>
    </GlobalForm>
    <!-- 添加海报 -->
    <el-dialog title='添加海报' :visible.sync="csetionShow" :close-on-click-modal="false" width="700px">
      <el-form :model="createinitData" :rules="createformRules" ref="ruleForm" label-width="106px">
        <el-form-item label="海报类型:" label-width="110px" prop="posterType">
          <el-select clearable v-model="createinitData.posterType" placeholder="请选择">
            <el-option v-for="item in posterTypeoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="海报图片:" label-width="110px" prop="posterImg">
          <ElImgUpload placeTxt="最多上传1张图片，大小不超过2M，图片尺寸为700px *100px，格式为JPG，PNG或GIF" :exist-image="createinitData.posterImg"
            @handleDelete="handleUploadDelete" @handleChange="handleUploadChange($event)" />
        </el-form-item>
        <el-form-item label="上传压缩文件:" label-width="110px" prop="posterOriginalFile">
          <FielUpload placeTxt="仅支持.zip,.rar格式,单次上传大小不超过10M" :existFile="createinitData.posterOriginalFile"
            @handleDelete="handleUploadfileDelete" @handleChange="handleUploadFileChange($event)" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('ruleForm')">取 消</el-button>
        <el-button size="small" type="primary" :loading="btnLoading" @click="submitAdd('ruleForm')">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script >
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import FielUpload from "../../../components/global/components/elements/uploadFile";
import moment from 'moment'
import _api from "@/utils/request";
export default {
  components: { ElImgUpload, FielUpload },
  data() {
    return {
      paramsExcel: {},
      posterTypeoptions: [{
        value: '01',
        label: '换新活动海报'
      },],
      rankType: "02",
      queryDate: "",
      dateType: "today",
      handleShow: false,
      isFrozen: true,
      downloadinitData: {
        entryTime: '',//moment().format("YYYY-MM-DD 23:59:59")
      },
      page3: {
        pageNum: 1,
        pageSize: 10,
      },
      redenvedata: [],
      loading3: false,
      redenvelopeslist: [
        {
          key: "entryTime",
          type: "date",
          clearable: true,
          placeholder: "请选择时间",
        },
        {
          slotName: "botton",
        },
      ],
      btnLoading: false,
      csetionShow: false,
      iid: null,
      initData: {
        entryTime: [moment().day(moment().day()-30).format('YYYY-MM-DD 00:00:00'),moment().format('YYYY-MM-DD 23:59:59')]
      },
      createinitData: {
        posterImg: "",
        posterOriginalFile: "",
        posterType: ''
      },
      userAccount: '',
      shanpsSelectList: [],
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      createformRules: {
        posterType: [
          { required: true, message: '请选择海报类型', trigger: 'change' }
        ],
        posterOriginalFile: [
          {
            required: true,
            trigger: "blur",
            message: "请上传压缩文件",
            max: 200,
          },
        ],
        posterImg: [
          {
            required: true,
            trigger: "blur",
            message: "请上传海报图片",
          },
        ],
      },
      formItemList: [
        { slotName: "operationSelect" },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间",
          clearable: true,
          lableWidth: '50',
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        { slotName: 'AddSlot' }
      ],
    };
  },
  created() {
    this.getadminSelectList()
    this.handleConfirm(this.initData)
  },
  watch: {
    downloadinitData: {
      handler(newValue, oldValue) {
        // 处理对象变化
        console.log(newValue);

      },
      deep: true
    },
  },
  methods: {
    //添加人
    getadminSelectList() {
      _api.StaffManageList({ pageNum: 1, pageSize: 99999 }).then((res) => {
        this.shanpsSelectList = res.data.records;
      });
    },
    switchTrigin(type) {
      this.rankType = type
      this.redenvellistFn();
    },
    dataObtain(way) {
      this.dateType = way;
      this.downloadinitData.entryTime = ''
      this.redenvellistFn();
    },
    addOneMenu() {
      this.csetionShow = true
    },
    // 确认添加文案
    submitAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          console.log(this.createinitData);
          _api.saveposterInfo(this.createinitData).then(res => {
            if (res.code === 1) {
              this.csetionShow = false
              this.$message({
                message: '添加成功！',
                type: 'success'
              });
              this.$refs[formName].resetFields();
              this.createinitData.posterOriginalFile = "";
              this.createinitData.posterImg = "";
              this.handleConfirm();
            }
            this.btnLoading = false
          }).catch(err => {
            this.btnLoading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    //删除图片
    handleUploadDelete() {
      this.createinitData.posterImg = "";
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadChange(url) {
      this.createinitData.posterImg = url;
    },
    //删除文件
    handleUploadfileDelete() {
      this.createinitData.posterOriginalFile = "";
    },
    // 上传文件后，将信息保存到对应参数中
    handleUploadFileChange(url) {
      console.log(url);
      this.createinitData.posterOriginalFile = url;
    },
    //添加文案弹窗关闭
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.csetionShow = false
      this.createinitData.posterOriginalFile = "";
      this.createinitData.posterImg = "";
    },
    handleConfirm(data,cd) {
      console.log(data, this.userAccount);
      let SeachParams = {}
      if (data) {
        let startTime = "";
        let endTime = "";
        if (data.entryTime === null) {
          startTime = '';
          endTime = '';
        } else {
          startTime = moment(data.entryTime[0]).format("x");
          endTime = moment(data.entryTime[1]).format("x");
        }
        SeachParams = {
          startTime: startTime,
          endTime: endTime,
          adminAccount: this.userAccount || "",
          pageNum: 1,
          pageSize: 10,
          disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
        };
      }
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    selecthandleChange(e) {
      this.iid = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.global-form {
  /deep/.el-input__inner {
    width: 160px;
  }
}

.tip {
  color: #FF687B;
}

.time-select {
  display: flex;
  margin-left: 20px;

  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    cursor: pointer;
  }

  .btnType-after2 {
    // width: 33%;
    width: 80px;
    min-width: max-content;
    height: 30px;
    background: #ffffff;
    color: #0981ff;
    line-height: 30px;
    border-radius: 20px;
  }

  .btnType2 {
    width: 80px;
    min-width: max-content;
    height: 30px;
    background: #0981ff;
    color: white;
    line-height: 30px;
    border-radius: 20px;
  }
}

.andleShowbox {
  border: 1px solid #0981ff;
  width: 200px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;

  .andleShowstyle {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0981ff;
    cursor: pointer;
    user-select: none;
  }

  .andleShowcolor {
    background-color: #0981ff;
    color: #fff;
  }
}
</style>
